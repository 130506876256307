<template>
  <div v-if="this.$store.getters.loggedIn" class="block-content">
    <div style="height: 100vh; overflow: hidden;">
      <div class="flex max-h-screen relative lg:static">
        <!-- App navigation layout -->
        <app-navigation-bar class="shadow-4 z-5"/>

        <!-- Content layout -->
        <div class="max-h-screen flex flex-column relative flex-auto overflow-auto">
          <div class="px-2 flex flex-column flex-auto">
            <p-toast position="top-center"/>
            <p-confirm-dialog/>
            <test-environment-banner v-if="this.$store.getters.testEnvironment"/>
            <router-view/>
            <p class="text-center footerText">K-Store - {{ this.$store.getters.appConfig.company_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view v-else />

</template>

<script>
import AppNavigationBar from "./components/NewAppNavigationBar.vue"
import Toast from "primevue/toast"
import ConfirmDialog from "primevue/confirmdialog"
import TestEnvironmentBanner from "@/components/TestEnvironmentBanner"

export default {
  components: {
    AppNavigationBar,
    TestEnvironmentBanner,
    "p-toast" : Toast,
    "p-confirm-dialog" : ConfirmDialog
  },
  created () {
    const userString = localStorage.getItem("user")

    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit("SET_USER_DATA", userData)
    }
  },
}
</script>
