<template>
  <div class="menu">
    <p-mega-menu
        :model="items"
        orientation="vertical"
        class="min-h-screen"
        :class="{ 'expandMegamenu' : expandMenu }"
        @blur="updateTooltips"
    >
      <template #start>
        <div v-show="!expandMenu" style="border-bottom: 1px solid black;">
          <img
              src="../assets/images/kstorefavicon.svg"
              alt="Keymas Logo"
              width="25"
              height="25"
              class="menuLogoSmall"
              @click="toggleNavigationMenu"
              v-tooltip.right="{
                value: 'Expand menu',
                pt: {
                  root: {
                    style: {
                      marginLeft: '10px'
                    }
                  },
                  text: {
                    style: {
                      fontSize: '12px',
                      padding: '0.6rem 0.6rem'
                    }
                  }
                }
              }"
          />
        </div>
        <div v-show="expandMenu" style="border-bottom: 1px solid black;">
          <img
              src="../assets/images/kstore_vector.svg"
              alt="Keymas Logo"
              width="112"
              height="25"
              class="menuLogo"
              @click="toggleNavigationMenu"
              v-tooltip.right="{
                value: 'Collapse menu',
                pt: {
                  text: {
                    style: {
                      fontSize: '12px',
                      padding: '0.6rem 0.6rem'
                    }
                  }
                }
              }"
          />
        </div>
      </template>

      <!-- Vue router templating -->
      <template #item="{ item }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route">
          <a :href="href" @click="navigate" class="flex align-items-center cursor-pointer overflow-hidden font-semibold text-sm p-2">
            <span class="routerLinkIcon" :class="item.icon"/>
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" class="flex align-items-center cursor-pointer overflow-hidden font-semibold text-sm p-2">
          <span class="menuIcon" :class="item.icon"/>
          <span class="ml-5">{{ item.label }}</span>
        </a>
      </template>
    </p-mega-menu>
  </div>
</template>

<script>
import MegaMenu from "primevue/megamenu"
import Permissions from "../services/PermissionsService"

export default {
  name: "NewAppNavigationBar",
  components: {
    "p-mega-menu" : MegaMenu
  },
  watch: {
    expandMenu () {
      this.updateTooltips()
    }
  },
  created () {
    // Dynamically populate plugins menu options based on user app config:
    let userPlugins = this.$store.getters.userPlugins

    // If a user has plugins:
    if( typeof userPlugins != "undefined" || userPlugins != null ) {
      if (userPlugins.length > 0) {

        let reportsMenuIndex = this.items.findIndex(element => element.label === "Reports")
        let pluginsMenu = [
          {
            label: "Plugins",
            icon: "fas fa-plug",
            items: [
              [
                {
                  label: "Plugins",
                  items: []
                }
              ]
            ]
          }
        ]

        userPlugins.map(element => {
          pluginsMenu[0].items[0][0].items.push(
              {
                label: element.name,
                url: element.url,
                id: element.id,
                route: { name: "Plugin Template", params: { name: element.name, url: element.url, id: element.id } },
                icon: "fas fa-plug"
              }
          )
        })

        // Add plugins menu option after the reports index:
        this.items.splice(reportsMenuIndex + 1, 0, pluginsMenu[0])
      }
    }
  },
  mounted () {
    // Focus on input when the DOM has finished loading:
    this.$nextTick(() => {
      // Dynamically create top menu tooltips:
      let listItems = document.querySelectorAll("ul.p-megamenu-root-list > li")

      for (let i = 0; i < listItems.length; i++) {
        let itemName = listItems[i].innerText
        listItems[i].classList.add("showTooltip")

        let container = document.createElement("div")
        container.id = itemName + "_tooltip"
        container.classList.add("tooltipContainer")

        let tooltipArrow = document.createElement("div")
        tooltipArrow.className = "tooltipArrow"
        container.appendChild(tooltipArrow)

        let tooltipText = document.createElement("div")
        tooltipText.className = "tooltipText"
        tooltipText.innerHTML = ""
        tooltipText.appendChild(document.createTextNode(itemName))
        container.appendChild(tooltipText)

        listItems[i].appendChild(container)
      }
    })
  },
  data () {
    return {
      expandMenu: false,
      items : [
        {
          label: "Dashboard",
          icon: "fas fa-house",
          class: "showTooltip menuOption",
          command: () => this.$router.push({ name: "Dashboard" })
        },
        {
          label: "Goods In",
          icon: "fas fa-truck-ramp-box",
          class: "showTooltip",
          style: "white-space: nowrap;", // Needed on nav items with spaces in the label
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let goodsInDashboard = Permissions.checkPageView("Goods In Dashboard")
            let goodsIn = Permissions.checkPageView("Goods In")

            return !(!goodsInDashboard && !goodsIn)
          },
          items: [
            [
              {
                label: "Goods In Options",
                items: [
                  {
                    label: "Goods In Dashboard",
                    icon: "fas fa-gauge",
                    route: { name: "Goods In Dashboard" },
                    visible: () => Permissions.checkPageView("Goods In Dashboard")
                  },
                  {
                    label: "Goods In",
                    icon: "fas fa-truck-ramp-box",
                    route: { name: "Goods In" },
                    visible: () => Permissions.checkPageView("Goods In")
                  },
                  {
                    label: "Goods In ASN",
                    icon: "fas fa-truck-ramp-box",
                    route: { name: "Goods In ASN" },
                    visible: () => Permissions.checkPageView("Goods In ASNs")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Orders",
          icon: "fas fa-book",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let ordersDashboard = Permissions.checkPageView("Orders Dashboard")
            let orders = Permissions.checkPageView("Orders")
            let orderReturns = Permissions.checkPageView("Order Returns")
            let pickingIssues = Permissions.checkPageView("Picking Issues")
            let pickingSettings = Permissions.checkPageView("Picking Settings")

            return !(!ordersDashboard && !orders && !orderReturns && !pickingSettings && !pickingIssues)
          },
          items: [
            [
              {
                label: "Order Options",
                items: [
                  {
                    label: "Order Dashboard",
                    icon: "fas fa-gauge",
                    route: { name: "Orders Dashboard" },
                    visible: () => Permissions.checkPageView("Orders Dashboard")
                  },
                  {
                    label: "Orders",
                    icon: "fas fa-book",
                    route: { name: "Orders" },
                    visible: () => Permissions.checkPageView("Orders")
                  },
                  {
                    label: "Order Returns",
                    icon: "fas fa-right-left",
                    route: { name: "Order Returns" },
                    visible: () => Permissions.checkPageView("Order Returns")
                  }
                ]
              }
            ],
            [
              {
                label: "Picking Options",
                items: [
                  {
                    label: "Picking Issues",
                    icon: "fas fa-person-circle-exclamation",
                    route: { name: "Picking Issues" },
                    visible: () => Permissions.checkPageView("Picking Issues")
                  },
                  {
                    label: "Picking Settings",
                    icon: "fas fa-gear",
                    route: { name: "Picking Settings" },
                    visible: () => Permissions.checkPageView("Picking Settings")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Items",
          icon: "fas fa-cube",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let items = Permissions.checkPageView("Items")
            let itemCategories = Permissions.checkPageView("Item Categories")
            let pcBuilds = Permissions.checkPageView("PC Builds")
            let stock = Permissions.checkPageView("Stock")
            let stockLevels = Permissions.checkPageView("Stock Levels")
            let stockHolds = Permissions.checkPageView("Stock Holds")
            let stockChecks = Permissions.checkPageView("Stock Checks")
            let stockChecksAuto = Permissions.checkPageView("Stock Checks Auto")

            return !(!items && !itemCategories && !pcBuilds && !stock && !stockLevels && !stockHolds && !stockChecks &&
                !stockChecksAuto)
          },
          items: [
            [
              {
                label: "Item Options",
                items: [
                  {
                    label: "Items",
                    icon: "fas fa-cube",
                    route: { name: "Items" },
                    visible: () => Permissions.checkPageView("Items")
                  },
                  {
                    label: "Item Categories",
                    icon: "fas fa-cubes-stacked",
                    route: { name: "Item Categories" },
                    visible: () => Permissions.checkPageView("Item Categories")
                  },
                  {
                    label: "PC Builds",
                    icon: "fas fa-screwdriver-wrench",
                    route: { name: "PC Builds" },
                    visible: () => Permissions.checkPageView("PC Builds"),
                  },
                  {
                    label: "Stock",
                    icon: "fas fa-cubes",
                    route: { name: "Stock" },
                    visible: () => Permissions.checkPageView("Stock")
                  }
                ]
              }
            ],
            [
              {
                label: "Empty",
                style: "opacity: 0",
                items: [
                  {
                    label: "Stock Levels",
                    icon: "fas fa-chart-simple",
                    route: { name: "Stock Levels" },
                    visible: () => Permissions.checkPageView("Stock Levels")
                  },
                  {
                    label: "Stock Holds",
                    icon: "fas fa-box-archive",
                    route: { name: "Stock Holds" },
                    visible: () => Permissions.checkPageView("Stock Holds")
                  },
                  {
                    label: "Stock Checks",
                    icon: "fas fa-square-check",
                    route: { name: "Stock Checks" },
                    visible: () => Permissions.checkPageView("Stock Checks")
                  },
                  {
                    label: "Stock Checks Auto",
                    icon: "fas fa-square-check",
                    route: { name: "Stock Checks Auto" },
                    visible: () => Permissions.checkPageView("Stock Checks Auto")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Storage",
          icon: "fas fa-boxes-stacked",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let locations = Permissions.checkPageView("Locations")
            let replenishments = Permissions.checkPageView("Replenishments")
            let locationTypes = Permissions.checkPageView("Location Types")
            let locationDimensions = Permissions.checkPageView("Location Dimensions")

            let containers = Permissions.checkPageView("Containers")
            let containerTypes = Permissions.checkPageView("Container Types")
            let packTypes = Permissions.checkPageView("Pack Types")

            return !(!locations && !replenishments && !locationTypes && !locationDimensions && !containers &&
                !containerTypes && !packTypes)
          },
          items: [
            [
              {
                label: "Location Options",
                items: [
                  {
                    label: "Locations",
                    icon: "fas fa-location-dot",
                    route: { name: "Locations" },
                    visible: () => Permissions.checkPageView("Locations")
                  },
                  {
                    label: "Replenishments",
                    icon: "fas fa-cubes",
                    route: { name: "Replenishments" },
                    visible: () => Permissions.checkPageView("Replenishments")
                  },
                  {
                    label: "Location Types",
                    icon: "fas fa-location-pin",
                    route: { name: "Location Types" },
                    visible: () => Permissions.checkPageView("Location Types")
                  },
                  {
                    label: "Location Dimensions",
                    icon: "fas fa-arrows-up-down-left-right",
                    route: { name: "Location Dimensions" },
                    visible: () => Permissions.checkPageView("Location Dimensions")
                  }
                ]
              }
            ],
            [
              {
                label: "Container Options",
                items: [
                  {
                    label: "Containers",
                    icon: "fas fa-boxes-stacked",
                    route: { name: "Containers" },
                    visible: () => Permissions.checkPageView("Containers")
                  },
                  {
                    label: "Container Types",
                    icon: "fas fa-box",
                    route: { name: "Container Types" },
                    visible: () => Permissions.checkPageView("Container Types")
                  },
                  {
                    label: "Pack Types",
                    icon: "fas fa-box",
                    route: { name: "Pack Types" },
                    visible: () => Permissions.checkPageView("Pack Types")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Dispatches",
          icon: "fas fa-truck",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let dispatches = Permissions.checkPageView("Dispatches")

            return !(!dispatches)
          },
          items: [
            [
              {
                label: "Dispatch Options",
                items: [
                  {
                    label: "Packing Containers",
                    icon: "fas fa-boxes-stacked",
                    route: { name: "Packing Containers" },
                    visible: () => Permissions.checkPageView("Packing Containers")
                  },
                  {
                    label: "Shipments",
                    icon: "fas fa-truck-fast",
                    route: { name: "Shipments" },
                    visible: () => Permissions.checkPageView("Shipments")
                  },
                  {
                    label: "Order dispatches",
                    icon: "fas fa-truck",
                    route: { name: "Dispatches" },
                    visible: () => Permissions.checkPageView("Dispatches")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Works",
          icon: "fas fa-industry",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let worksOrders = Permissions.checkPageView("Works Orders")

            return !(!worksOrders)
          },
          items: [
            [
              {
                label: "Works Options",
                items: [
                  {
                    label: "Works Orders",
                    icon: "fas fa-book",
                    route: { name: "Works Orders" },
                    visible: () => Permissions.checkPageView("Works Orders"),
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Quality",
          icon: "fas fa-microscope",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let inspection = Permissions.checkPageView("Quality Inspection")
            let qualityDashboard = Permissions.checkPageView("Quality Dashboard")
            let nonConformance = Permissions.checkPageView("Non Conformance Reports")

            return !(!inspection && !qualityDashboard && !nonConformance)
          },
          items: [
            [
              {
                label: "Quality Options",
                items: [
                  {
                    label: "Quality Dashboard",
                    icon: "fas fa-gauge",
                    // route: { name: "Quality Dashboard" },
                    visible: () => Permissions.checkPageView("Quality Dashboard")
                  },
                  {
                    label: "Inspection",
                    icon: "fas fa-microscope",
                    route: { name: "Inspection" },
                    visible: () => Permissions.checkPageView("Inspection")
                  },
                  {
                    label: "NCR",
                    icon: "fas fa-ban",
                    route: { name: "Non Conformance Reports" },
                    visible: () => Permissions.checkPageView("Non Conformance Reports")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Accounts",
          icon: "fas fa-address-book",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let accounts = Permissions.checkPageView("Accounts")
            let addresses = Permissions.checkPageView("Addresses")
            let owners = Permissions.checkPageView("Owners")
            let users = Permissions.checkPageView("Users")
            let userGroups = Permissions.checkPageView("User Groups")

            return !(!accounts && !addresses && !owners && !users && !userGroups)
          },
          items: [
            [
              {
                label: "Accounts",
                items: [
                  {
                    label: "Accounts",
                    icon: "fas fa-address-book",
                    route: { name: "Accounts" },
                    visible: () => Permissions.checkPageView("Accounts")
                  },
                  {
                    label: "Addresses",
                    icon: "fas fa-address-card",
                    route: { name: "Addresses" },
                    visible: () => Permissions.checkPageView("Addresses")
                  },
                  {
                    label: "Owners",
                    icon: "fas fa-user-tie",
                    route: { name: "Owners" },
                    visible: () => {
                      let appConfig = this.$store.getters.appConfig

                      if (Permissions.checkPageView("Owners") && appConfig.enable_owners) {
                        return true
                      }
                      else {
                        return false
                      }
                    }
                  }
                ]
              }
            ],
            [
              {
                label: "User Accounts",
                items: [
                  {
                    label: "Users",
                    icon: "fas fa-users",
                    route: { name: "Users" },
                    visible: () => Permissions.checkPageView("Users"),
                  },
                  {
                    label: "User Groups",
                    icon: "fas fa-user-group",
                    route: { name: "User Groups" },
                    visible: () => Permissions.checkPageView("User Groups")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Warehouse",
          icon: "fas fa-warehouse",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let myWarehouse = Permissions.checkPageView("My Warehouse")
            let warehouses = Permissions.checkPageView("Warehouses")
            let warehouseZones = Permissions.checkPageView("Warehouse Zones")

            return !(!myWarehouse && !warehouses && !warehouseZones)
          },
          items: [
            [
              {
                label: "Warehouse Options",
                items: [
                  {
                    label: "Visualise Warehouse",
                    icon: "fas fa-warehouse",
                    route: { name: "My Warehouse" },
                    visible: () => Permissions.checkPageView("My Warehouse"),
                  },
                  {
                    label: "Warehouses",
                    icon: "fas fa-warehouse",
                    route: { name: "Warehouses" },
                    visible: () => Permissions.checkPageView("Warehouses"),
                  },
                  {
                    label: "Warehouse Zones",
                    icon: "fas fa-table-cells",
                    route: { name: "Warehouse Zones" },
                    visible: () => Permissions.checkPageView("Warehouse Zones")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "System",
          icon: "fas fa-gear",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let appConfig = Permissions.checkPageView("App Config")
            let integrations = Permissions.checkPageView("Integrations")
            let databaseBackups = Permissions.checkPageView("Database Backups")
            let documentTemplates = Permissions.checkPageView("Document Templates")
            let reasonCodes = Permissions.checkPageView("Reason Codes")
            let tags = Permissions.checkPageView("Tags")

            return !(!appConfig && !integrations && !databaseBackups && !documentTemplates && !reasonCodes && !tags)
          },
          items: [
            [
              {
                label: "System Settings",
                items: [
                  {
                    label: "App Config",
                    icon: "fas fa-gear",
                    route: { name: "App Config" },
                    visible: () => Permissions.checkPageView("App Config")
                  },
                  {
                    label: "Integrations",
                    icon: "fas fa-plug",
                    route: { name: "Integrations" },
                    visible: () => Permissions.checkPageView("Integrations")
                  },
                  {
                    label: "Database Backups",
                    icon: "fas fa-database",
                    route: { name: "Database Backups" },
                    visible: () => Permissions.checkPageView("Database Backups")
                  },
                  {
                    label: "Plugins",
                    icon: "fas fa-plug",
                    route: { name: "Plugins" },
                    visible: () => Permissions.checkPageView("App Config")
                  }
                ]
              }
            ],
            [
              {
                label: "Empty",
                style: "opacity: 0",
                items: [
                  {
                    label: "Reason Codes",
                    icon: "fas fa-code",
                    route: { name: "Reason Codes" },
                    visible: () => Permissions.checkPageView("Reason Codes")
                  },
                  {
                    label: "Tags",
                    icon: "fas fa-tags",
                    route: { name: "Tags" },
                    visible: () => Permissions.checkPageView("Tags")
                  },
                  {
                    label: "Document Templates",
                    icon: "fas fa-file-code",
                    route: { name: "Document Templates" },
                    visible: () => Permissions.checkPageView("Document Templates")
                  },
                ]
              }
            ]
          ]
        },
        {
          label: "Reports",
          icon: "fas fa-clipboard-question",
          class: "showTooltip",
          visible: () => {
            // Check permissions of sub items and return false if all permissions are false:
            let movementLogs = Permissions.checkPageView("Movement Logs")
            let reports = Permissions.checkPageView("Reports")
            let kpis = Permissions.checkPageView("KPIs")

            return !(!reports && !kpis && !movementLogs)
          },
          items: [
            [
              {
                label: "Reports",
                items: [
                  {
                    label: "Movement Logs",
                    icon: "fas fa-arrow-right-arrow-left",
                    route: { name: "Movement Logs" },
                    visible: () => Permissions.checkPageView("Movement Logs")
                  },
                  {
                    label: "Reports",
                    icon: "fas fa-clipboard-question",
                    route: { name: "Reports" },
                    visible: () => Permissions.checkPageView("Reports")
                  },
                  {
                    label: "KPIs",
                    icon: "fas fa-square-poll-vertical",
                    route: { name: "KPIs" },
                    visible: () => Permissions.checkPageView("KPIs")
                  }
                ]
              }
            ]
          ]
        },
        {
          label: "Logout",
          icon: "fas fa-right-from-bracket",
          class: "mt-8",
          command: () => this.logout()
        }
      ]
    }
  },
  methods: {
    updateTooltips () {
      this.$nextTick(() => {
        setTimeout(() => {
          let listItems = document.querySelectorAll("ul.p-megamenu-root-list > li")

          if (this.expandMenu === false) {
            for (let i = 0; i < listItems.length; i++) {
              listItems[i].classList.add("showTooltip")
            }
          } else {
            for (let i = 0; i < listItems.length; i++) {
              listItems[i].classList.remove("showTooltip")
            }
          }
        }, 50)
      })
    },
    logout () {
      this.$store.dispatch("logout")
    },
    toggleNavigationMenu () {
      this.expandMenu = !this.expandMenu
    }
  }
}
</script>

<style scoped>
/* Mega menu */

.menu >>> .p-megamenu.p-megamenu-vertical {
  width: 2.5rem !important;
  min-width: 0;
  background-image: linear-gradient(180deg, #009ad7 0%, #003b64 100%);
  padding: 0;
  /*background-color: var(--primary-color);*/
  border-left: 0;
  border-top: 0;
  /*border-right: 1px solid black;*/
  border-right: none;
  border-bottom: 0;
  border-radius: 0;
}

::v-deep(.p-megamenu-start) {
  padding-top: 4px;
  background-color: white;
}

/* Align the tooltips and menu panels in line with the menu item */
.menu >>> .p-megamenu-root-list > .p-menuitem {
  position: relative;
}

/* Menu logo */

.menuLogoSmall {
  margin: 2px 0 5px 7px;
}
.menuLogoSmall:hover {
  cursor: pointer;
}
.menuLogo {
  margin: 2px 0 5px 0;
}
.menuLogo:hover {
  cursor: pointer;
}
.expandMegamenu {
  min-width: 11rem !important;
}

/* Menu item styling */

/* Menu options with sub menu styling */
::v-deep(.p-menuitem-content) {
  color: whitesmoke;
}
/* Menuitem hover state */
::v-deep(.p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover) {
  color: #4b5563;
  background: #f3f4f6;
}
/* Menuitem focus state */
::v-deep(.p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:focus) {
  color: #4b5563;
  background: #f3f4f6;
}
/* Menuitem click and remember focus state */
::v-deep(.p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content) {
  color: #4b5563;
  background: #f3f4f6;
}
/* Menuitem click and highlight state */
::v-deep(.p-megamenu .p-menuitem.p-highlight > .p-menuitem-content) {
  color: #4b5563;
  background: #f3f4f6;
}

/* Sub menu styling */

/* Sub menu header */
.menu >>> .p-megamenu-submenu-header {
  padding-top: 0;
  padding-left: 0.55rem;
  font-size: 14px;
}

/* Sub menu panel */
.menu >>> .p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #495057;
  /*border: 1px solid black;*/
  /*border-radius: 0 6px 6px 6px;*/
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  overflow: auto !important;
}

.menu >>> .p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 15rem;
}

.menu >>> .menuIcon {
  width: 18px !important;
  height: 18px !important;
  position: absolute;
  text-align: center;
}

/*.menu >>> .routerLinkIcon {*/
/*  position: absolute;*/
/*  text-align: center;*/
/*}*/

.menu >>> .p-menuitem-text {
  margin-left: 30px;
  white-space: nowrap;
  font-size: 14px;
}

::v-deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
  padding-left: 10px !important;
}


/* Tooltips */

::v-deep(.tooltipContainer) {
  visibility: hidden;
}
::v-deep(.tooltipContainer:hover) {
  visibility: hidden !important;
}
::v-deep(.showTooltip:hover > .tooltipContainer) {
  visibility: visible;
  opacity: 1;
}
::v-deep(.tooltipContainer) {
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  align-items: center;
  position: absolute;
  left: 100%;
  top: 0;
}
::v-deep(.tooltipArrow) {
  top: 50%;
  left: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}
::v-deep(.tooltipText) {
  background: #495057;
  color: #ffffff;
  padding: 0.6rem 0.6rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 12px;
}

/*!* Laptop media query *!*/
@media only screen and (max-height: 900px) {
  /* App menu */
  .menu >>> .menuIcon {
    margin-top: 0.6rem !important;
  }

  .menu >>> .p-megamenu.p-megamenu-vertical {
    width: 2.8rem;
  }
  .menu >>> .p-menuitem-text {
    font-size: 0.8rem;
  }
  .menu >>> .p-megamenu-submenu-header {
    font-size: 0.9rem;
  }

  .menuLogoSmall {
    margin: 2px 0 5px 2px;
  }
  .menuLogo {
    margin: 2px 0 5px 0;
  }
}
</style>